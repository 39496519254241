import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';  
import { Compte } from '../../shared/models/compte';
import { COMPTES } from 'src/app/shared/models/dataJson';

@Injectable({
  providedIn: 'root'
})
export class CompteService {


  

  constructor() {
    
  }

  getAll(): Compte[] {

    let planComptable: Compte[]=[];

    COMPTES.forEach((compte) => {

      let c = new Compte(compte.nom);
      c.numero = compte.numero;
      planComptable.push(compte);
    });

    return planComptable;

  }

  getComptesByNature(nature: string): Compte[]{
    let compteActifs: Compte[]=[];

    const res = COMPTES.filter(c => c.nature == nature )
    return res;
  }






}
