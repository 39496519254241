import { FormGroup } from "@angular/forms";
import { Credit } from "./credit";
import { Debit } from "./debit";

export class QuestionJournal {

    //id: string;
    texte: string;
    debits: Debit[];
    credits: Credit[];
    isCorrect?: boolean;
    
    constructor(debits: Debit[], credits:Credit[]){

        this.debits = debits;
        this.credits = credits
    }





}
