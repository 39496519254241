<div class="row justify-content-md-center">
  <div class="col-sm-6">

    <div class="card mx-auto">
      <h4 class="card-header">Login</h4>
      <div class="card-body">

      <form [formGroup]="signinForm" (ngSubmit)="onSubmit()">
    

        <div class="form-floating form-group mb-2">
          <input type="email"
                  id="floatingInput"
                  class="form-control"
                  formControlName="email"
                  placeholder="name@example.com" [ngClass]="{ 'is-invalid': submitted && email.errors}" />
                  <div *ngIf="submitted && email.errors" class="invalid-feedback">
                      <div *ngIf="email.errors.required">Adresse email requise</div>
                      <div *ngIf="email.errors.email">Format de l'adresee incorrect</div>
                    </div>

                    
                  <label for="floatingInput">Adresse email</label>
        </div>
        <div class="form-floating form-group mb-2">
          <input type="password"
                  id="floatingPassword"
                  class="form-control"
                  placeholder="Password"
                  formControlName="password" [ngClass]="{ 'is-invalid': submitted && email.errors}" >

                  <div *ngIf="submitted && password.errors" class="invalid-feedback">
                    <div *ngIf="password.errors.required">Mot de passe requis</div>
                    
                </div>
                  
          <label for="floatingPassword">Password</label>
        </div>

        
        <hr>
        <button class="w-100 btn btn-lg btn-primary" type="submit">GO</button>

        <hr>
        
        <div class="text-center"><a routerLink="/forgot" routerLinkActive="active" class="text-center">Mot de passe oublié</a></div>

        
        <!--<div class="text-center"><a routerLink="/register" routerLinkActive="active" class="text-center">S'inscrire</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mot de passe oublié</div>-->
      </form>

      </div>
    </div>
  </div>
</div>

