import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  signinForm: FormGroup;
  errorMessage: string;
  submitted = false;

  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };
  
  constructor(private formBuilder: FormBuilder,
    public authService: AuthentificationService,
    private router: Router,
    private alert: AlertService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.signinForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  get email() { return this.signinForm.get('email'); }

  get password() { return this.signinForm.get('password'); }

  onSubmit() {
     
    this.submitted = true;
    const email = this.signinForm.get('email').value;
    const password = this.signinForm.get('password').value;
        
    this.authService.SignIn(email,password);
  }
      
  

}
