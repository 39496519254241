import { Component, OnInit } from '@angular/core';
import { Module } from '../shared/models/module';


@Component({
  selector: 'app-protected',
  templateUrl: './protected.component.html',
  styleUrls: ['./protected.component.css']
})
export class ProtectedComponent implements OnInit {

  degreID: string;
  module: Module;
  journalId: string;
  showModules: boolean = false;
  showDegre: boolean = true;
  showJournal: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.showModules = false;
    this.showDegre = true;
    this.showJournal = false;

  }


  changeDegre($event: string){
    this.degreID = $event;

    this.showDegre = false;
    this.showModules = true;
  }

  changeJournal($event: any){
    this.module = $event.module;
    this.journalId=$event.journalId;

    this.showDegre = false;
    this.showModules = false;
    this.showJournal = true;
  }

}
