export class Compte {
    //id: string;
    numero: number;
    nom: string;


    constructor(nom: string){
        //this.id = id; 
        this.nom = nom;
    }
}
