<nav class="navbar navbar-expand navbar-light bg-light">
    <div class="container-fluid">
       <a class="navbar-brand">COMPT'APP</a>
       <div class="collapse navbar-collapse">
            <ul class="navbar-nav mr-auto">
                <li routerLinkActive="active" class="nav-item">
                    <a routerLink="home" class="nav-link">Accueil</a>
                </li>
            </ul>
        
            <ul class="navbar-nav ml-auto">
                <li *ngIf="!(authService.user$ | async)" routerLinkActive="active" class="nav-item">
                <a routerLink="login" class="nav-link">Connexion</a>
                </li>
          
            </ul>


            <div class="navbar-nav" *ngIf="authService.user$  | async as user">
                <li class="nav-item dropdown ">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        
                    
                        <img src="{{user.photoURL ?? 'https://firebasestorage.googleapis.com/v0/b/angular-test-project-7f533.appspot.com/o/images%2Fprofile%2Favatar_default.png?alt=media&token=d271fb4d-8654-4192-871c-4c1c7a0abb2d'}}" class="rounded-circle" style="width: 25px;" alt="Avatar"/>

                        <!--
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                        </svg>-->


                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        
                        <li><a class="dropdown-item"routerLinkActive="active" routerLink="profile" >Profile</a></li>
                        <li><a class="dropdown-item" routerLinkActive="active" routerLink="go">C'est parti!</a></li>
                        
                        


                        <ng-container *ngIf="authService.user$ | async as user">
                            <li *ngIf="user.admin"><a class="dropdown-item" routerLinkActive="active" routerLink="importJournal">Import journal</a></li>
                            <li *ngIf="user.admin"><a class="dropdown-item" routerLinkActive="active" routerLink="/register">Inscription</a></li>
                        </ng-container>

                        <li><hr class="dropdown-divider"></li>
                        <li><a  role="button" class="dropdown-item" (click)="logOut()">Se déconnecter</a></li>
                    </ul>
                </li>
            </div>

           
 
      </div>  
    </div>
 </nav>

