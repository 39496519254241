import { Observable, Subscription, timer } from "rxjs";

export class Timer {
    public minutes: number = 0;
    public secondes: number = 0;
    public totalSecondes: number = 0;
    private timer: any;

    public minutesDisplayed: string = "00";
    public secondesDisplayed: string = "00";

    public timerObs: Observable<any>;
    public timeSub: Subscription;




    start() {
     

      this.timerObs = timer(1000,1000);
      this.timeSub = this.timerObs.subscribe((value) => {
        this.totalSecondes = value;

        this.minutes = Math.floor(++this.totalSecondes / 60);
        this.secondes = (this.totalSecondes - this.minutes * 60);  

        this.minutesDisplayed = this.minutes.toString().padStart(2,'0');
        this.secondesDisplayed = this.secondes.toString().padStart(2, '0');   
        
      }  
      );
    }

    stop() {
      this.timeSub.unsubscribe();
    }
    
    reset() {
      this.totalSecondes = this.minutes = this.secondes = 0;
    }
  }