import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/internal/operators/first';
import { Reward } from 'src/app/shared/models/reward';

@Injectable({
  providedIn: 'root'
})
export class RewardService {

  constructor(private angularBdd: AngularFirestore) { }

  getRewardsByUser(userId: string){
    return this.angularBdd.collection<Reward>("users/"+userId+"/rewards", ref => ref.orderBy("createdDate","asc")).valueChanges().pipe(first());
  }

  createReward(userId: string, reward: Reward){

        reward.createdDate = new Date();
        this.angularBdd.collection("users/"+userId+"/rewards",ref => ref.where("moduleId", "==", reward.moduleId)).snapshotChanges().pipe(first())
        .subscribe(res => {
          if(res.length>0){
            console.log("reward existe --> mise à jour");
            this.angularBdd.doc("users/"+userId+"/rewards/"+res[0].payload.doc.id).update(Object.assign({}, reward));
          }else{
            console.log("n'esiste pas");
            return new Promise<any>((resolve, reject) =>{
              this.angularBdd
                  .collection("users/"+userId+"/rewards")
                  .add(Object.assign({},reward))
                  .then(res => {
                      console.log("REWARD CREE");
                    }, 
                    err => reject(err));
          });
          }
        })
  }
}
