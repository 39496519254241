import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule
  ],
   // Ajouter l’exportation suivante : 
  exports: [ 
       CommonModule,
       FormsModule,
       ReactiveFormsModule
    ] 
})
export class SharedModule { }
