import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router, UrlSegment } from  "@angular/router";
import * as firebase from 'firebase';
import { concat, isError } from 'lodash';
import { observable, Observable } from 'rxjs';
import { concatMap, first, map, switchMap } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user';
import { AlertService } from './alert.service';
import { of } from 'rxjs';
import { JsonpClientBackend } from '@angular/common/http';
import { HotToastService } from '@ngneat/hot-toast';



@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {
  
  userData: any;

  user$: Observable<User>;


  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    //private db: AngularFireDatabase,
    public afs: AngularFirestore,
    private alert: AlertService,
    private toast: HotToastService)
    {
      this.user$ = this.afAuth.authState.pipe(switchMap((user) => {
        if (user) {
          this.userData = user;      
          //localStorage.setItem('user', JSON.stringify(this.userData));
          
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
        } else {
          return of(null);
        }
      }))

    }


    SignIn(email:string, password:string) {
      return this.afAuth.signInWithEmailAndPassword(email, password)
        .then((result) => {
          
          
              
          this.afs.doc<User>(`users/${result.user.uid}`).valueChanges().subscribe(res=>{
            this.userData = res;
            localStorage.setItem('user', JSON.stringify(res));
            
          })

          this.router.navigate(['profile']);         
 
        })
        
        .catch((error) => {
          //window.alert(error.message);
          this.toast.error(error.message)
          //this.alert.clear();
          //this.alert.error(error.message,this.options);
        });
    }

    signupUser(email: string, password: string, nom: string, prenom: string, classe: string) {

      this.afAuth.createUserWithEmailAndPassword(email, password)
        .then((result) =>{

          
          const user : User = new User();
          user.email = result.user.email;
          user.uid = result.user.uid;
          user.nom = nom;
          user.prenom = prenom;
          user.score = 0;
          user.displayName = prenom+" "+nom;
          user.class = classe;
          user.admin=false;


          this.afs.collection("users").doc(result.user.uid).set(Object.assign({},user)).then(res=>{
            localStorage.setItem('user', JSON.stringify(user));

            this.userData = user;     

          })

          this.router.navigate(['profile']);
       
          
        })
        .catch((error) => {
          this.alert.clear();
          this.alert.error(error.message,this.options);
        });
    }

    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return (user !== null) ? true : false;
    }

    SignOut(){
      return this.afAuth.signOut().then(() => {
        localStorage.removeItem('user');
        this.router.navigate(['home']);
      });
  
    }    

    resetPasswordInit(email: string) { 
      return this.afAuth.sendPasswordResetEmail(
        email, 
        { url: 'https://angular-test-project-7f533.firebaseapp.com/__/auth/action' }); 
    } 

    updateProfileData(profileData: any): Observable<any>{
      const user = this.afAuth.currentUser;
      
      console.log(profileData);

      return of(user).pipe(concatMap(u =>{
        return this.afAuth.updateCurrentUser(profileData);
      }))
    
    }


}

