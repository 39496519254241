<div class="container px-4 py-5">
    <div class="main-body">

          <div class="row gutters-sm">
            <div class="col-md-2 mb-3">
              <div class="card bg-transparent bg-gradient border-0">
                <div class="card-body profile-image">
                  <div class="d-flex flex-column align-items-center text-center">

      
                   
             
                    

                    <ng-container *ngIf="authService.user$ | async as user">
                      
                      

                    
                    <img src="{{user.photoURL ?? 'https://firebasestorage.googleapis.com/v0/b/angular-test-project-7f533.appspot.com/o/images%2Fprofile%2Favatar_default.png?alt=media&token=d271fb4d-8654-4192-871c-4c1c7a0abb2d'}}" alt="Admin" class=" border rounded-circle " width="150">
                    
                      
                      <button type="button" class="btn btn-outline-secondary" (click)=inputField.click()>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
        </svg>
                      </button>
                      
                          <input #inputField accept="image/x-png,image/gif,image/jpeg"  type="file" hidden (change)="uploadImage($event, user)">    
                      </ng-container>
                      
                      
                    
                  </div>
                </div>
              </div>
            
            </div>
            <div class="col-md-5">
              <div class="card mb-3 border-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Nom</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <ng-container *ngIf="authService.user$ | async as user">
                        {{user.nom}}
                      </ng-container>
                      
                      
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Prénom</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <ng-container *ngIf="authService.user$ | async as user">
                        {{user.prenom}}
                      </ng-container>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Classe</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <ng-container *ngIf="authService.user$ | async as user">
                        {{user.class}}
                      </ng-container>
                    </div>
                  </div>
               
                
                </div>
              </div>
            </div>


            <div class="col-md-5 mb-3">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-center">Mon score - 
                      <mark><ng-container *ngIf="authService.user$ | async as user">
                        {{user.score | number}} points
                      </ng-container></mark>
                    </h5>
                    <app-score></app-score>  

                  
                </div>
              </div>  
            </div>
          </div>

          <div class="row">
            <button type="button" class="btn btn-outline-success btn-lg" routerLink="/go">C'est parti !</button>
          </div>

          
          <div class="row ">
            <div class="col-12 col-lg-4 mt-3 mb-3">
              <div >
                  <app-timeline></app-timeline>              
              </div>
            </div>
            <div class="col-12 col-lg-4 mt-3 mb-3">
              <div class="card">
                
                <div class="card-body">
                  <h5 class="card-title">Mes résultats</h5>
                  
                  <app-progress></app-progress>  

                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 mt-3 mb-3">
              <div class="card">
                
                <div class="card-body">
                  <h5 class="card-title">Mes récompenses</h5>
                  <app-reward></app-reward>  
                </div>
              </div>
            </div>

          </div>
          

  

        </div>
    </div>