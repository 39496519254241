import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthentificationService } from './authentification.service';
import { AlertService } from './alert.service';
import { map, take, tap } from 'rxjs/operators';
import * as firebase from 'firebase';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthentificationService,
              private router: Router,
              private alert: AlertService) { }

  canActivate(

  
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>| boolean | Promise <boolean> {

        return new Promise ((resolve, reject) =>{
            firebase.default.auth().onAuthStateChanged((user : firebase.default.User)=>{
              if (user){
                
                resolve (true);
              }else{
              
                reject(false);
                this.router.navigate(['login']);
              }
            })
        });

  }
}