import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { ModuleService } from 'src/app/core/services/module.service';
import { RewardService } from 'src/app/core/services/reward.service';
import { Reward } from 'src/app/shared/models/reward';
import { BADGES } from 'src/app/shared/models/dataJson';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.css']
})
export class RewardComponent implements OnInit {

  rew : Reward[];
  nbRows: any[];
  userId : string;
  
  constructor(private _moduleService: ModuleService, private authService : AuthentificationService, private _rewardService : RewardService) { }

  ngOnInit(): void {

    this.authService.user$.pipe(first()).subscribe( user => {
      if (user) { 
        this.userId = user.uid;
        this.getRewards();
      }
    });

    

  }

  getRewards (){
    this._rewardService.getRewardsByUser(this.userId).subscribe(res=>{
      res.map(r=>{
      
        r.url = BADGES.find(o => o.module === r.moduleId).badges[r.rating-1];
        console.log(r.url);
        return r;
      })
      this.rew = res;

      

    })
  }

}
