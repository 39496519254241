import { Component, OnInit } from '@angular/core';






@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
 
  title = 'Comptapp:)';
  formContent: any;
  formData: any;
  activeStepIndex: number;


  ngOnInit(): void {

    //this.formContent = STEP_ITEMS;
    //this.formData = {};

  }

}
