<h5 class="bg-info text-dark">Actifs</h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Compte</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ca of comptesActif; let caIndex=index" >
                  <th scope="row">{{ca.numero}}</th>
                  <td>{{ca.nom}}</td>
                </tr>
              
              </tbody>
            </table>
            <h5 class="bg-info text-dark">Passifs</h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Compte</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cp of comptesPassif; let cpIndex=index" >
                  <th scope="row">{{cp.numero}}</th>
                  <td>{{cp.nom}}</td>
                </tr>
              
              </tbody>
            </table>
            <h5 class="bg-info text-dark">Charges</h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Compte</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cc of comptesCharge; let ccIndex=index" >
                  <th scope="row">{{cc.numero}}</th>
                  <td>{{cc.nom}}</td>
                </tr>
              
              </tbody>
            </table>
            <h5 class="bg-info text-dark">Produits</h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Compte</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cp of comptesProduit; let cpIndex=index" >
                  <th scope="row">{{cp.numero}}</th>
                  <td>{{cp.nom}}</td>
                </tr>
              
              </tbody>
            </table>
