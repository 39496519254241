import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';  
import { QuestionJournal } from '../../shared/models/questionJournal';


@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private dbPath = '/questions';

  questionsRef: AngularFirestoreCollection<QuestionJournal>;

  constructor(private db: AngularFirestore) {
    this.questionsRef = db.collection(this.dbPath);
  }

  getAll(): AngularFirestoreCollection<QuestionJournal> {
    return this.questionsRef;
  }

  addQuestion(question: QuestionJournal): void {
    this.questionsRef.add(question);
  }
  
  
}
