import { Compte } from "./compte";

export class Debit {
    id: string;
    compte: Compte;
    montant: number;

    constructor(compte: Compte, montant: number){

        this.compte = compte;
        this.montant = montant;
    }
}
