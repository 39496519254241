import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { stringify } from 'querystring';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Resultat } from 'src/app/shared/models/resultat';
import { ModuleService } from './module.service';


@Injectable({
  providedIn: 'root'
})
export class ResultatService {

  constructor(private angularBdd: AngularFirestore, private _moduleService : ModuleService) { }

  createResultat(resultat: Resultat, moduleId: string) {
    
    return new Promise<any>((resolve, reject) =>{
        this.angularBdd
            .collection("resultats")
            .add(Object.assign({},resultat))
            .then(res => {
                this._moduleService.setModuleScore(moduleId, resultat.userId )
              }, 
              err => reject(err));
    });
  }

  getXLastResultsFromUid(uid: String, numberRes: number): Observable<Resultat[]>{

    //return this.angularBdd.collection('resultats', ref => ref.where('userId', '==', uid).orderBy("createdDate", "desc").limit(numberRes)).valueChanges();
    return this.angularBdd.collectionGroup<Resultat>('resultats', ref => ref.where('userId', '==', uid).orderBy("createdDate", "desc").limit(numberRes)).valueChanges().pipe();
  }

  isBestResultat(uid:string, quizId: string, resultat: Resultat, moduleId: string): Observable<boolean>{

    let ret = false;
    let temp : Object[];
    var subject = new Subject<boolean>();
   
    let sub = this.angularBdd.collection('resultats', ref => ref.where('userId', '==', uid).where('quizId', '==', quizId).orderBy('points', 'desc').limit(1)).valueChanges({ idField: 'id' }).pipe(first()).subscribe((res:any) =>
      
      {
        //S'il existe un résultat, voir si le score est plus élevé
        if(res.length!=0){
          resultat.isFirst = false;
          //si oui, laisser isBest a false
          if(res[0].points >= resultat.points){
            resultat.isBest =false;

          }else{
            //sinon, c'est le meilleur score, il faut donc mettre l'ancien à false
            ret = true;
            resultat.isBest = true;
            this.angularBdd.doc(`resultats/${res[0].id}`).update({isBest: false});


             //Mettre à jour le score de l'utilisateur

             //décremtner et incrémenter
            const increment = firebase.default.firestore.FieldValue.increment(resultat.points-res[0].points);
            this.angularBdd.doc(`users/${uid}`).update({score: increment});
            
          }
          this.createResultat(resultat, moduleId);
          
          
        }else{
          resultat.isFirst = true;
          if(resultat.points == 0){
            resultat.isBest = false;

            this.createResultat(resultat, moduleId);

          }else{
            ret = true;
            resultat.isBest = true;
            this.createResultat(resultat, moduleId);

            //Mettre à jour le score de l'utilisateur
            const increment = firebase.default.firestore.FieldValue.increment(resultat.points);
            this.angularBdd.doc(`users/${uid}`).update({score: increment});

          }
          
          
        }

        let finalRet: boolean = false;
        if(ret==true && resultat.isFirst==false){
          finalRet=true;
        }

        subject.next(finalRet);
      }

      
      
      
    )

    return subject.asObservable();
  
  }  

  getBestPourcentage(uid: string, quizId: string){

    return this.angularBdd.collection('resultats', ref => ref.where('userId', '==', uid).where('quizId', '==', quizId).where('isBest', '==', true).limit(1)).valueChanges().pipe(first())

  }
}


