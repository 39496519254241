import { Credit } from "./credit";
import { Debit } from "./debit";
import { QuestionJournal } from "./questionJournal";

export class QuizJournal {

    id: string;
    titre: string;
    questions: QuestionJournal[]
    
    constructor(titre: string, questions: QuestionJournal[]){

        this.titre = titre;
        this.questions = questions;
    }

    /*formatQuestion(){

        this.questions.forEach((data:any, i:any) => {
     
            let quest : QuestionJournal;
            
            let debits : Array<Debit>;
            debits  = [];
            let credits: Array<Credit>;
            credits = [];
        })

    }*/
}


export class QuizJournalToStore{
  
        titre: string;

        questions: [
          {
            texte: string,
            debits: [
              {
                compte: string,
                montant: number
              }
            ],
            credit: [
              {
                compte: string,
                montant: number
              }
            ]
          }
        ]


      
}
