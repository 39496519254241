import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { QuestionService } from 'src/app/core/services/question.service';
import { CompteService } from 'src/app/core/services/compte.service';
import { QuestionJournal } from 'src/app/shared/models/questionJournal';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'; 
import { map } from 'rxjs/operators';
import { Form, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Compte } from 'src/app/shared/models/compte';
import { ArgumentOutOfRangeError, Observable, of, timer } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Debit } from 'src/app/shared/models/debit';
import { Credit } from 'src/app/shared/models/credit';
import * as _ from 'lodash';
import { isEqual } from 'lodash';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';


import { isEqualHelper} from 'src/helper/isEqualHelper';
import { CITATIONS1, CITATIONS2, CITATIONS3, COMPTES } from 'src/app/shared/models/dataJson';

import { ActivatedRoute, Router } from '@angular/router';

import { QuizJournalService } from 'src/app/core/services/quiz-journal.service';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { Timer } from 'src/app/shared/models/timer';
import { Resultat } from 'src/app/shared/models/resultat';
import * as firebase from 'firebase';
import { ResultatService } from 'src/app/core/services/resultat.service';
import { Module } from 'src/app/shared/models/module';
import { ModuleService } from 'src/app/core/services/module.service';
import * as confetti from 'canvas-confetti';








@Component({
  selector: 'app-quiz-journal',
  templateUrl: './quiz-journal.component.html',
  styleUrls: ['./quiz-journal.component.css']
})
export class QuizJournalComponent implements OnInit {



 // @Input() formContent: any;

  @Input() journalId: string;
  @Input() module: Module;

  

  //formContent = STEP_ITEMS;
  formContent : [];
  quizJournalId: string;
  quizTitle: string;
  moduleName: string;

  stepItems: Array<any>;
  form: FormGroup;
  activeStepIndex: number;
  questionsNumber: number;

  questionFormat : QuestionJournal[];
  questionAnswers: QuestionJournal[];

  planComptable : Compte[]=[];


  mode = "quiz";
  
  dataForm: FormGroup;
  reviewAnswers: QuestionJournal [];
  pourcentageReussite: number = 0;

  _timer: Timer = new Timer();

  resultat: Resultat;
  message1: string="";
  message2: string="";





  constructor(
      private router: Router, 
      private activatedroute:ActivatedRoute,
      private _compteService: CompteService, 
      private _questionService: QuestionService, 
      private fb: FormBuilder, 
      private _quizJournalService: QuizJournalService, 
      private _authentificatinService: AuthentificationService, 
      private _resultatService: ResultatService, 
      private _moduleService : ModuleService,
      private renderer2: Renderer2,
      private elementRef: ElementRef
  ) { 

    this._timer.start();

    this.form = this.fb.group({
      questionArray: this.fb.array([])
      });
  }  
  
  initTransactions(){
    return this.fb.group({      
      debit: [''],
      debitNat: [''],
      credit: [''],
      creditNat: [''],
      montant: [''],

    })
    
  }

  
  get questionArray() {
    return this.form.get('questionArray') as FormArray;
 }

  getTransactions(index:number){
    const group=(this.form.get('questionArray') as FormArray).at(index)
    return (group.get('Transactions') as FormArray)
  }

  initQuiz(){


    
    this.retrieveCompte();


    this.stepItems = this.formContent;
    
    
    this.questionFormat = this.formatQuestion();

    this.activeStepIndex = 0;
    this.questionsNumber = this.stepItems.length;

    this.stepItems.forEach((data, i) => {

      this.questionArray.push(this.fb.group({Transactions:this.fb.array([this.initTransactions()])}));  


    });

  }
 

  ngOnInit() {

    
    this.quizJournalId = this.journalId;
    this.moduleName = this.module.titre;
    
    
    this.retrieveQuestion();

    
  }



  goToStep(step: string): void {
    
    

    this.activeStepIndex =
      step === 'prev' ? this.activeStepIndex - 1 : this.activeStepIndex + 1;


  

  }

  addTransaction(ix: number) {

    const control = (<FormArray>this.form.controls['questionArray']).at(ix).get('Transactions') as FormArray;
    control.push(this.initTransactions());
  }

  removeTransaction (ix: number, transaction: number){
    const control = (<FormArray>this.form.controls['questionArray']).at(ix).get('Transactions') as FormArray;
    control.removeAt(transaction);
  }

  formatAnswer(): Array<QuestionJournal>{
    let reponses : Array<QuestionJournal>;
    reponses = [];

    this.form.value['questionArray'].forEach((data:any, i:any) => {
     
      let quest : QuestionJournal;
      
      let debits : Array<Debit>;
      debits  = [];
      let credits: Array<Credit>;
      credits = [];

      data['Transactions'].forEach((element: any) => {
        
        //Si aucun compte rempli, ignoré la ligne
        if(element['debit']=="" && element['credit']==""){return }
        
        
        //controle si le compte débité est deja au débit ou au crédit, ajouter ou soustraire
        if(debits.findIndex(x => x.compte['nom'] === element['debit'])==-1){

          if(credits.findIndex(x => x.compte['nom'] === element['debit'])==-1){

            //console.log(element['debit']+": ce debit n'existe pas au crédit");
            debits.push(new Debit(new Compte(element['debit']),parseInt(element['montant'])));  

          }else{

            //console.log(element['debit']+": ce debit EXISTE au crédit");
            
            let ind = credits.findIndex(x => x.compte['nom'] === element['debit']);
            credits[ind].montant -= parseInt(element['montant']);
            if(credits[ind].montant==0){
              credits.splice(ind, 1);
            }
            
          }
       
        }else{
            let ind = debits.findIndex(x => x.compte['nom'] === element['debit']);
            debits[ind].montant += parseInt(element['montant']);
            if(debits[ind].montant==0){
              debits.splice(ind, 1);
            }
               
        };


        //controle si le compte crédité deja au débit ou au crédit, ajouter ou soustraire
        if(credits.findIndex(x => x.compte['nom'] === element['credit'])==-1){
          if(debits.findIndex(x => x.compte['nom'] === element['credit'])==-1){


            //console.log(element['credit']+": ce credit n'existe pas au débit");
            credits.push(new Credit(new Compte(element['credit']),parseInt(element['montant'])));  

          }else{

            let ind = debits.findIndex(x => x.compte['nom'] === element['credit']);
            debits[ind].montant -= parseInt(element['montant']);
            if(debits[ind].montant==0){
              debits.splice(ind, 1);
            }
            
          }
       
        }else{
            let ind = credits.findIndex(x => x.compte['nom'] === element['credit']);
            credits[ind].montant += parseInt(element['montant']);
            if(credits[ind].montant==0){
              credits.splice(ind, 1);
            }
               
        };

      });

        
       quest = new QuestionJournal(debits, credits);

       //console.log(quest);
       reponses.push(quest);
    });

   //console.log(reponses);
   return reponses;
  }

  
  formatQuestion(): QuestionJournal[]{
    let questionFormat : Array<QuestionJournal>;
    questionFormat = [];


    this.stepItems.forEach((data:any, i:any) => {
     
      let quest : QuestionJournal; 
      let debits : Array<Debit>;
      debits  = [];
      let credits: Array<Credit>;
      credits = [];

      //onsole.log(data);

      /*
      data['debits'].forEach((element:any) => {
        debits.push(new Debit(new Compte(element['compte']),parseInt(element['montant'])));
      });

      

      data['credits'].forEach((element:any) => {
        credits.push(new Credit(new Compte(element['compte']),parseInt(element['montant'])));
      });*/

      //console.log(data["debits"]);
      debits = data["debits"];
      credits = data["credits"];

      quest = new QuestionJournal(debits, credits);
      //console.log(data['data']['text']);
      //console.log(data['texte']);
      quest.texte = data['texte'];
      questionFormat.push(quest);

      
    });

     //console.log(questionFormat);
     return questionFormat
  }

  controlAnswers(){

    this.questionAnswers = this.formatAnswer();
    
    let questions: QuestionJournal[] = [];
    let debitIsCorrect : boolean;
    let creditIsCorrect : boolean;

    this.reviewAnswers = [];
    
    /*
    console.log("Réponse: ");
    console.log(this.questionAnswers);
    console.log("Questions: ");
    console.log(this.questionFormat);
    */

    questions = this.questionFormat;

    //console.log(questions);
    
    questions.forEach((element: QuestionJournal, index: number) => {
    
       let debitsSorted: Array<Debit> = _.sortBy(element.debits, 
        [function(o) { return o.compte.nom; }]);

        let creditsSorted: Array<Credit> = _.sortBy(element.credits, 
          [function(o) { return o.compte.nom; }]);


       let debitsAnswerSorted: Array<Debit> = _.sortBy(this.questionAnswers[index].debits, 
        [function(o) { return o.compte.nom; }]);

        let creditsAnswerSorted: Array<Credit> = _.sortBy(this.questionAnswers[index].credits, 
          [function(o) { return o.compte.nom; }]);
        

 

        debitIsCorrect = isEqualHelper(debitsSorted, debitsAnswerSorted);
        creditIsCorrect = isEqualHelper(creditsSorted, creditsAnswerSorted);

        if (debitIsCorrect && creditIsCorrect ){
            let rq ={} as QuestionJournal;
           
            rq.texte = element.texte;
            rq.isCorrect = true;
            this.pourcentageReussite++;

            this.reviewAnswers.push(rq);
        }else{
           let rq ={} as QuestionJournal;
           
          rq.texte = element.texte;
          rq.isCorrect = false;

           this.reviewAnswers.push(rq);
        }
    });

    
   //console.log(reviewAnswers);

  }

 
  onFormSubmit(): void {
   
    
    this.mode = "result";
    
    
    this.controlAnswers();

    this._timer.stop();

    
    this.resultat = new Resultat(this.quizJournalId, this._authentificatinService.userData.uid, this.pourcentageReussite*1000, this.pourcentageReussite / this.questionsNumber, this.moduleName, this.quizTitle);
    
    //On va chercher les messages à afficher selon le score
    this.getMessagesToDisplay();
    //On met à jour le nombre de point. Si % de réussite = 0, aucun point.
    this.pourcentageReussite>0 ? this.resultat.updatePoints(this._timer.totalSecondes):this.resultat.points=0;

    //On vérifie si c'est meilleur score pour mettre à jour les données
    let best = this._resultatService.isBestResultat(this._authentificatinService.userData.uid, this.quizJournalId, this.resultat, this.module.id);
    
    

    //si c'est le meilleur score, confetti
    best.subscribe(r=>{
      
      if(r==true){
        
        const canvas = this.renderer2.createElement('canvas');
      
          //console.log(this.renderer2.parentNode(this.elementRef.nativeElement));
          this.renderer2.appendChild(this.renderer2.parentNode(this.elementRef.nativeElement), canvas);
          //this.renderer2.insertBefore(this.elementRef.nativeElement, canvas,null);
      
          const myConfetti = confetti.create(canvas, {
            resize: true // will fit all screen sizes
          });
      
          myConfetti( {
            particleCount: 100,
            spread: 70,
            zIndex: 80
          });
      }
    })

          

          

   
     

   
  }


  getMessagesToDisplay(){
    if(this.resultat.pourcentageReussite<0.6){
      this.message1 ="Il reste passablement de travail, mais ne vous découragez pas!";
      //var item = items[Math.floor(Math.random()*items.length)];
      this.message2 = CITATIONS1[Math.floor(Math.random()*CITATIONS1.length)];
    }else if (this.resultat.pourcentageReussite<0.9){

      this.message1 ="C'est bien! Les bases fondamentales semblent être acquises. Continuez comme ça!";
      this.message2 = CITATIONS2[Math.floor(Math.random()*CITATIONS2.length)];
    }else{
      this.message1 ="Super résultat, bravo!";
      this.message2 = CITATIONS3[Math.floor(Math.random()*CITATIONS3.length)];
    }
  }

  //pour pouvoir recharger le component des modules
  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }

  backToModule(){
    this.redirectTo('/go');
  }



  trackByFn(index: number): number {
    return index;
  }

  

  retrieveQuestion(): void {
    
    
    this._quizJournalService.getJournalByID(this.quizJournalId).pipe(
                                                 
     //this._quizJournalService.getJournalByID("v4lBs2pn2AhudOlXT8Hz").pipe(
      map((res: any )=> {
       
        // insert logic 
       /*const data = res.map((obj :any) => (
         {
          titre: obj.titre,
          questions: obj.questions
        }
        
        ));*/
       
        return res;
        //return res;
      })

    ).subscribe((res: any)=>
      {
        
        setTimeout(() => {
          this.formContent = res["questions"];
          
          this.quizTitle = res["titre"];
          this.initQuiz();
          //console.log(this.formContent);
        }, 0);
        
      }
      
      //this.data=res.json())
      //console.log(res)
      )

      
  }

  

  retrieveCompte(): void {

    this.planComptable = this._compteService.getAll();
  }


}

