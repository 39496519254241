import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-degre',
  templateUrl: './degre.component.html',
  styleUrls: ['./degre.component.css']
})
export class DegreComponent implements OnInit {

  @Output() degreIDSelectedEvent = new EventEmitter<string>();

  visible: boolean = true;
  
  constructor() { }

  ngOnInit(): void {
    this.visible = true;
  }

  ngOnChanges(): void{
    
  }

  selectDegreID(id: string){
    this.degreIDSelectedEvent.emit(id);
    this.visible = false;

  }

}
