  const DEGRE = [
    {
      "nom": "MP1"
    },
    {
      "nom": "MP2"
    },
    {
      "nom": "MP3"
    }
  ]

  export {DEGRE}
  
  
  
  const COMPTES = [
        {
          "numero": 1000,
          "nom": "Caisse",
          "nature": "Actif",
        },
        {
          "numero": 1010,
          "nom": "Poste",
          "nature": "Actif",
        },
        {
          "numero": 1020,
          "nom": "Banque",
          "nature": "Actif",
        },
        {
          "numero": 1060,
          "nom": "Titre de placement",
          "nature": "Actif",
        },
        {
          "numero": 1100,
          "nom": "Créances clients",
          "nature": "Actif",
        },
        {
          "numero": 1108,
          "nom": "Créances douteuses",
          "nature": "Actif",
        },
        {
          "numero": 1109,
          "nom": "Ducroire",
          "nature": "Actif",
        },
        {
          "numero": 1140,
          "nom": "Avances de salaire",
          "nature": "Actif",
        },
        {
          "numero": 1170,
          "nom": "IP s/marchandises et services",
          "nature": "Actif",
        },
        {
          "numero": 1171,
          "nom": "IP s/investissements et ACE",
          "nature": "Actif",
        },
        {
          "numero": 1176,
          "nom": "Créances AFC",
          "nature": "Actif",
        },
        {
          "numero": 1190,
          "nom": "Autres créances",
          "nature": "Actif",
        },
        {
          "numero": 1200,
          "nom": "Stock de marchandises",
          "nature": "Actif",
        },
        {
          "numero": 1300,
          "nom": "Actifs transitoires",
          "nature": "Actif",
        },
        {
          "numero": 1500,
          "nom": "Machines et appareils",
          "nature": "Actif",
        },
        {
          "numero": 1510,
          "nom": "Mobilier et installations",
          "nature": "Actif",
        },
        {
          "numero": 1520,
          "nom": "Machines de bureau",
          "nature": "Actif",
        },
        {
          "numero": 1521,
          "nom": "Infrastructure informatique",
          "nature": "Actif",
        },
        {
          "numero": 1530,
          "nom": "Véhicules",
          "nature": "Actif",
        },
        {
          "numero": 1540,
          "nom": "Instruments et outillage",
          "nature": "Actif",
        },
        {
          "numero": 1600,
          "nom": "Immeubles",
          "nature": "Actif",
        },
        {
          "numero": 1700,
          "nom": "Brevets",
          "nature": "Actif",
        },
        {
          "numero": 2000,
          "nom": "Dettes fournisseurs",
          "nature": "Passif",
        },
        {
          "numero": 2010,
          "nom": "Autres dettes",
          "nature": "Passif",
        },
        {
          "numero": 2170,
          "nom": "Dettes AVS",
          "nature": "Passif",
        },
        {
          "numero": 2171,
          "nom": "Dettes LPP",
          "nature": "Passif",
        },
        {
          "numero": 2172,
          "nom": "Dettes ALFA",
          "nature": "Passif",
        },
        {
          "numero": 2200,
          "nom": "TVA due",
          "nature": "Passif",
        },
        {
          "numero": 2206,
          "nom": "Dettes AFC",
          "nature": "Passif",
        },
        {
          "numero": 2250,
          "nom": "Dettes immeubles",
          "nature": "Passif",
        },
        {
          "numero": 2300,
          "nom": "Passifs transitoires",
          "nature": "Passif",
        },
        {
          "numero": 2400,
          "nom": "Emprunt bancaire à long terme",
          "nature": "Passif",
        },
        {
          "numero": 2401,
          "nom": "Dettes hypothécaires",
          "nature": "Passif",
        },
        {
          "numero": 2500,
          "nom": "Emprunt à long terme auprès de tiers",
          "nature": "Passif",
        },
        {
          "numero": 2800,
          "nom": "Capital",
          "nature": "Passif",
        },
        {
          "numero": 2850,
          "nom": "Privé",
          "nature": "Passif",
        },
        {
          "numero": 3200,
          "nom": "Ventes de marchandises",
          "nature": "Produits",
        },
        {
          "numero": 3209,
          "nom": "Déductions accordées",
          "nature": "Produits",
        },
        {
          "numero": 3210,
          "nom": "Frais de ventes",
          "nature": "Produits",
        },
        {
          "numero": 3400,
          "nom": "Honoraires",
          "nature": "Produits",
        },
        {
          "numero": 3720,
          "nom": "Prestations propres",
          "nature": "Produits",
        },
        {
          "numero": 3805,
          "nom": "Pertes s/créances",
          "nature": "Produits",
        },
        {
          "numero": 4200,
          "nom": "Achats de marchandises",
          "nature": "Charges",
        },
        {
          "numero": 4209,
          "nom": "Déductions obtenues",
          "nature": "Charges",
        },
        {
          "numero": 4270,
          "nom": "Frais d'achats",
          "nature": "Charges",
        },
        {
          "numero": 4280,
          "nom": "Variation de stocks",
          "nature": "Charges",
        },
        {
          "numero": 5200,
          "nom": "Salaires",
          "nature": "Charges",
        },
        {
          "numero": 5270,
          "nom": "Charges sociales",
          "nature": "Charges",
        },
        {
          "numero": 6000,
          "nom": "Loyers",
          "nature": "Charges",
        },
        {
          "numero": 6100,
          "nom": "Entretiens et réparations",
          "nature": "Charges",
        },
        {
          "numero": 6200,
          "nom": "Frais de véhicule",
          "nature": "Charges",
        },
        {
          "numero": 6280,
          "nom": "Frais d'expédition",
          "nature": "Charges",
        },
        {
          "numero": 6300,
          "nom": "Assurances",
          "nature": "Charges",
        },
        {
          "numero": 6400,
          "nom": "Electricité, gaz (autres énergies)",
          "nature": "Charges",
        },
        {
          "numero": 6500,
          "nom": "Frais d'administration",
          "nature": "Charges",
        },
        {
          "numero": 6510,
          "nom": "Téléphone, fax, Internet",
          "nature": "Charges",
        },
        {
          "numero": 6570,
          "nom": "Frais d'informatique",
          "nature": "Charges",
        },
        {
          "numero": 6600,
          "nom": "Publicité",
          "nature": "Charges",
        },
        {
          "numero": 6700,
          "nom": "Autres charges d'exploitation (ACE)",
          "nature": "Charges",
        },
        {
          "numero": 6740,
          "nom": "Différence de caisse",
          "nature": "Charges",
        },
        {
          "numero": 6800,
          "nom": "Amortissements",
          "nature": "Charges",
        },
        {
          "numero": 6900,
          "nom": "Charges financières",
          "nature": "Charges",
        },
        {
          "numero": 6950,
          "nom": "Produits financiers",
          "nature": "Produits",
        },
        {
          "numero": 8000,
          "nom": "Charges d'immeubles",
          "nature": "Charges",
        },
        {
          "numero": 8100,
          "nom": "Produits d'immeubles",
          "nature": "Produits",
        },
        {
          "numero": 8500,
          "nom": "Charges extraordinaires",
          "nature": "Charges",
        },
        {
          "numero": 8510,
          "nom": "Produits extraordinaires",
          "nature": "Produits",
        }



        
        


    ]
  
 


  export { COMPTES }


  const BADGES = [
    {
      //bilan
      module: "UPv6znlTekHVRTQma9wu",
      badges: [
          "Bilan1.png",
          "Bilan2.png",
          "Bilan3.png",
          "Bilan4.png"
      ]
    },
    {
      //bilan
      module: "6aZ1ke7y9zL38JIrRW6L",
      badges: [
          "Immeubles1.png",
          "Immeubles2.png",
          "Immeubles3.png",
          "Immeubles4.png"
      ]
    }
  ]

  export { BADGES }


  const CITATIONS1 = [
    "Le travail est l'opium du peuple et je ne veux pas mourir drogué.",
    "L’ÉCHEC EST L’ÉPICE QUI DONNE SA SAVEUR AU SUCCÈS. ",
    "Le travail est pour moi la chose la plus sacrée !! ...... c'est pour ça que j'y touche pas !!!",
    "Si le travail c'est la santé, ben je préfère encore être malade !",
    "Paresse : habitude prise de se reposer avant la fatigue."
  ]

  export { CITATIONS1 }

  const CITATIONS2 = [
    "NE CRAIGNEZ PAS LA PERFECTION, VOUS N’Y PARVIENDREZ JAMAIS. ",
    "IL N’Y A QU’UNE FAÇON D’ÉCHOUER, C’EST D’ABANDONNER AVANT D’AVOIR RÉUSSI. ",
    "POUR POUVOIR CONTEMPLER UN ARC-EN-CIEL, IL FAUT D’ABORD ENDURER LA PLUIE.",
    "Chaque difficulté rencontrée doit être l’occasion d’un nouveau progrès",
    "Pour gagner, il faut risquer de perdre"
  ]

  export { CITATIONS2 }

  const CITATIONS3 = [
    "Apple cherche une nouveau directeur financier, le job est pour vous :)",
    "Wahoo!! Votre prof de compta doit être excellent :)"
  ]

  export { CITATIONS3}