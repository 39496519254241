import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { ModuleService } from 'src/app/core/services/module.service';
import { QuizJournalService } from 'src/app/core/services/quiz-journal.service';
import { Compte } from 'src/app/shared/models/compte';
import { Credit } from 'src/app/shared/models/credit';
import { Debit } from 'src/app/shared/models/debit';
import { Module } from 'src/app/shared/models/module';
import { QuestionJournal } from 'src/app/shared/models/questionJournal';
import { QuizJournal} from 'src/app/shared/models/quizJournal';
import * as XLSX from 'xlsx';



@Component({
  selector: 'app-import-journal',
  templateUrl: './import-journal.component.html',
  styleUrls: ['./import-journal.component.css']
})
export class ImportJournalComponent implements OnInit {

  modules$: Observable<Module[]>;

  errorMessage: string;
  submitted = false;

  
  importQuizForm: FormGroup = new FormGroup({});

  qj: QuizJournal;

  data: [any];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };


  constructor(private quizJournalService: QuizJournalService, private moduleService: ModuleService, private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    
    this.modules$ = this.moduleService.getAllModules();
    this.initForm();
  }

  initForm(){
    this.importQuizForm = this.formBuilder.group({
      name: ['', Validators.required],
      module: ['', Validators.required],
      file: ['', Validators.required]
  
    },
    { 
      //validator: this.ConfirmedValidator('password', 'confirmPassword')
    });
  }

  get name() { return this.importQuizForm.get('name'); }
  get module() { return this.importQuizForm.get('module'); }
  get file() { return this.importQuizForm.get('file'); }
  


  onSubmit(){

    this.submitted = true;

    if (!this.importQuizForm.valid) {
      
      return false;
    } else {
      const name = this.importQuizForm.get('name').value;
      const module = this.importQuizForm.get('module').value as Module;
      

      this.qj.titre=name;

      this.quizJournalService.createQuizJournal(this.qj, module);
      this.importQuizForm.reset();
    
      this.submitted = false;
      
    }
    
  }



  onFileChange(evt: any) {

    let uniqueRows : number[];

    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <[any]>(XLSX.utils.sheet_to_json(ws));

     
      uniqueRows = this.getUniqueLine(this.data);

      let journal : Array<QuestionJournal> = [];

      uniqueRows.forEach((uniqueNum: number) =>{

        let question: QuestionJournal;
        
        //let debits: {nom: string, montant: number}[] = [];
        //let credits: {nom: string, montant: number}[] = [];

        let debits: Debit[] = [];
        let credits: Credit[] = [];

        let texteQuestion: string = "";
        let compteur = 0;

        this.data.forEach((element: any ) => {
          //console.log(element['No']);
          if (uniqueNum === element['No']){
           
            /////////////////////////////////////////////////////////

            //controle si le compte débité est deja au débit ou au crédit, ajouter ou soustraire
            if(debits.findIndex(x => x.compte['nom'] === element['Debit'])==-1){

              if(credits.findIndex(x => x.compte['nom'] === element['Debit'])==-1){

                //console.log(element['debit']+": ce debit n'existe pas au crédit");
                debits.push(new Debit(new Compte(element['Debit']),parseInt(element['Montant'])));  

              }else{

                //console.log(element['debit']+": ce debit EXISTE au crédit");
                
                let ind = credits.findIndex(x => x.compte['nom'] === element['Debit']);
                credits[ind].montant -= parseInt(element['Montant']);
                if(credits[ind].montant==0){
                  credits.splice(ind, 1);
                }
                
              }
          
            }else{
                let ind = debits.findIndex(x => x.compte['nom'] === element['Debit']);
                debits[ind].montant += parseInt(element['Montant']);
                if(debits[ind].montant==0){
                  debits.splice(ind, 1);
                }
                  
            };


            //controle si le compte crédité deja au débit ou au crédit, ajouter ou soustraire
            if(credits.findIndex(x => x.compte['nom'] === element['Credit'])==-1){
              if(debits.findIndex(x => x.compte['nom'] === element['Credit'])==-1){


                //console.log(element['credit']+": ce credit n'existe pas au débit");
                credits.push(new Credit(new Compte(element['Credit']),parseInt(element['Montant'])));  

              }else{

                let ind = debits.findIndex(x => x.compte['nom'] === element['Credit']);
                debits[ind].montant -= parseInt(element['Montant']);
                if(debits[ind].montant==0){
                  debits.splice(ind, 1);
                }
                
              }
          
            }else{
                let ind = credits.findIndex(x => x.compte['nom'] === element['Credit']);
                credits[ind].montant += parseInt(element['Montant']);
                if(credits[ind].montant==0){
                  credits.splice(ind, 1);
                }
                  
            };









            ///////////////////////////////////////////////////////////
          

            //debits.push(new Debit(element['Debit'], element['Montant']));
            //credits.push(new Credit(element['Credit'], element['Montant']));

            if(compteur===0){
              texteQuestion=element['Texte'];
            };
            compteur++;

          }

          

        });
        
        

        question = new QuestionJournal(debits, credits);
        question.texte = texteQuestion;

        compteur = 0;
        debits = [];
        credits = [];
        texteQuestion = "";

        journal.push(question);
      });

      //this.quizJournalService.createQuizJournal(new QuizJournal("lsadkf", journal));
      this.qj = new QuizJournal("", journal);
      //console.log(this.qj);
      
    };
    reader.readAsBinaryString(target.files[0]);
  }


  getUniqueLine(data: [any]){

    let allNo : number[] = [];

    const unique = (value: any, index: any, self: any) => {
      return self.indexOf(value) === index
    }
    
    data.forEach((element: any ) => {
      //console.log(element['No']);
      allNo.push(element['No']);

    });

    
    const uniqueRows = allNo.filter(unique)

    return uniqueRows;
  }


}
