import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth-guard.service';
import { DegreDetailComponent } from './degre-detail/degre-detail.component';
import { DegreComponent } from './degre/degre.component';
import { ProfileComponent } from './profile/profile.component';
import { ProtectedComponent } from './protected.component';
import { QuizJournalComponent } from './quiz-journal/quiz-journal.component';

const routes: Routes = [
  
  { path: 'go',canActivate: [AuthGuard], component: ProtectedComponent },
  { path: 'profile',canActivate: [AuthGuard], component: ProfileComponent }
 

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProtectedRoutingModule { }
