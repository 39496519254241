<hr>
<h6 class="text-center"> {{userClass}} - Top 5 💪 </h6>
<hr>
<div *ngFor="let user of scores$ | async; let scoreIndex=index" >

    
    <ng-container  *ngIf="authService.user$ | async as userConnected">
        <ng-container *ngIf="userConnected.uid == user.uid; else elseBlock">
            <span class="text-warning">{{scoreIndex+1}} -  <img src="{{user.photoURL ?? 'https://firebasestorage.googleapis.com/v0/b/angular-test-project-7f533.appspot.com/o/images%2Fprofile%2Favatar_default.png?alt=media&token=d271fb4d-8654-4192-871c-4c1c7a0abb2d'}}" class="rounded-circle" style="width: 25px;" alt="Avatar"/>  {{user.prenom}} {{user.nom}} - {{user.score | number}}</span>
          </ng-container>

        <ng-template #elseBlock>{{scoreIndex+1}} - <img src="{{user.photoURL ?? 'https://firebasestorage.googleapis.com/v0/b/angular-test-project-7f533.appspot.com/o/images%2Fprofile%2Favatar_default.png?alt=media&token=d271fb4d-8654-4192-871c-4c1c7a0abb2d'}}" class="rounded-circle" style="width: 25px;" alt="Avatar"/> {{user.prenom}} {{user.nom}} - {{user.score | number}}</ng-template>
     
      </ng-container>

     
</div>