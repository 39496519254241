import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Console } from 'console';
import { Observable, Observer, Subscription } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { first } from 'rxjs/internal/operators/first';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { ResultatService } from 'src/app/core/services/resultat.service';
import { Resultat } from 'src/app/shared/models/resultat';
import { User } from 'src/app/shared/models/user';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {


  lastResulats: Resultat[] = [];
  sub: Subscription;

  userId : string;

  resultats$: Observable<Resultat[]>;
  
  constructor(private _resultatService: ResultatService, private _authService: AuthentificationService) { }

  ngOnInit(): void {
    
    this._authService.user$.pipe(first()).subscribe( user => {
      if (user) { 
          
          this.userId = user.uid 
          this.getLastActivites();
       }
    });
     
  }



  getLastActivites (){

      //this.resultats$ = this._resultatService.getXLastResultsFromUid(this.userId, 10);
      this.resultats$ = this._resultatService.getXLastResultsFromUid(this._authService.userData.uid, 10);
  }

  ngOnDestroy(): void{

  }


}
