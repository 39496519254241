<div class="row justify-content-md-center">
    <div class="col-sm-6">
  
      <div class="card mx-auto">
        <h4 class="card-header">Mot de passe oublié</h4>
        <div class="card-body">
  
        <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
      
  
          <div class="form-floating form-group mb-2">
            <input type="email"
                    id="floatingInput"
                    class="form-control"
                    formControlName="email"
                    placeholder="name@example.com" [ngClass]="{ 'is-invalid': submitted && email.errors}" />
                    <div *ngIf="submitted && email.errors" class="invalid-feedback">
                        <div *ngIf="email.errors.required">Adresse email requise</div>
                        <div *ngIf="email.errors.email">Format de l'adresee incorrect</div>
                      </div>
  
                      
                    <label for="floatingInput">Adresse email</label>
          </div>

          <hr>
            <button class="w-100 btn btn-lg btn-primary" type="submit">Envoyez-moi un nouveau mot de passe</button>

            <hr>
         
        </form>
  
        </div>
      </div>
    </div>
  </div>