<div class="container">
    <div class="row">
        <app-home-feature-card *ngFor="let feature of features"
          class="col-md-4"
          [title]="feature.title"
          [description]="feature.description"
          [icon]="feature.icon">
        </app-home-feature-card>
    </div>
 </div>
