import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire';  
import { AngularFireDatabaseModule } from '@angular/fire/database';  
import { AngularFirestoreModule } from '@angular/fire/firestore';  
import { environment } from 'src/environments/environment';  
import { HttpClientModule } from '@angular/common/http';  
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuthModule } from "@angular/fire/auth";


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuizJournalComponent } from './protected/quiz-journal/quiz-journal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from './core/core.module';
import { AuthentificationService } from './core/services/authentification.service';
import { AlertService } from './core/services/alert.service';
import { AuthGuard } from './core/services/auth-guard.service';
import { HotToastModule } from '@ngneat/hot-toast';
import { registerLocaleData } from '@angular/common';
import localeFr from "@angular/common/locales/fr-CH";

const appRoutes: Routes = [

 
];



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,  
    AngularFireModule.initializeApp(environment.firebase),  
    AngularFireDatabaseModule,  
    AngularFirestoreModule,
    RouterModule.forRoot(appRoutes),
    CoreModule,
    HotToastModule.forRoot()
  ],
  providers: [AngularFireModule, AuthentificationService, AlertService, AuthGuard, { provide: LOCALE_ID, useValue: 'fr-CH'}],
  bootstrap: [AppComponent]
})
export class AppModule { }

registerLocaleData(localeFr, "fr-CH");
