

<app-degre *ngIf="showDegre" (degreIDSelectedEvent)="changeDegre($event)"></app-degre>


<app-degre-detail *ngIf="showModules" [degreID]="degreID" (moduleSelectedEvent)="changeJournal($event)"></app-degre-detail>


<app-quiz-journal *ngIf="showJournal" [journalId]="journalId" [module]="module"></app-quiz-journal>



