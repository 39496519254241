<div class="row d-flex justify-content-center">
    <div class="col-md-12 col-12">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <h5 class="card-title">Mes activités</h5>
                <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">

                    <div *ngFor="let resultat of resultats$ | async; let resultatIndex=index" >
                        <div class="vertical-timeline-item vertical-timeline-element">
                            <div> <span class="vertical-timeline-element-icon bounce-in"> <i class="badge badge-dot badge-dot-xl badge-success"></i> </span>
                                <div class="vertical-timeline-element-content bounce-in">
                                    <h6 class="timeline-title">{{resultat.module}} - {{resultat.quizTitle}} 
                                        
                                        <span *ngIf="resultat.isBest ==true" class="badge bg-success">Best score!</span> 
                                    </h6>
                                    <p>{{resultat.points | number}} points   / {{resultat.pourcentageReussite*100 | number:'1.1-1'}}% de réussite</p> 
                                    <span class="vertical-timeline-element-date">{{resultat.createdDate.seconds* 1000 | date: 'dd/MM/yyyy'}}<br/>{{resultat.createdDate.seconds* 1000 | date: 'HH:mm'}}</span>

                                </div>
                            </div>
                        </div>
                    </div>






                </div>
            </div>
        </div>
    </div>
</div>