import { Injectable } from '@angular/core';
import { DEGRE } from 'src/app/shared/models/dataJson';
import { Degre } from 'src/app/shared/models/degre';

@Injectable({
  providedIn: 'root'
})
export class DegreService {

  constructor() { }

  getAll(): Degre[] {

    let degres: Degre[]=[];

    DEGRE.forEach((degre) => {

      let d = new Degre();
      d.nom = degre.nom;
      degres.push(d);
    });

    return degres;

  }
}
