import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthentificationService } from 'src/app/core/services/authentification.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private authService: AuthentificationService,
    private router: Router,
    private alert: AlertService) { }

  classes: any = ['MP1a','MP1b', 'MP1c', 'MP1d', 'MP1e', 'MP1f', 'MP2a','MP2b', 'MP2c', 'MP2d', 'MP2e', 'MP3a','MP3b','MP3c']

  signupForm: FormGroup = new FormGroup({});
  errorMessage: string;
  submitted = false;

  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.signupForm = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      classe: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    },
    { 
      validator: this.ConfirmedValidator('password', 'confirmPassword')
    });
  }

  get nom() { return this.signupForm.get('nom'); }

  get prenom() { return this.signupForm.get('prenom'); }

  get classe() { return this.signupForm.get('classe'); }

  get email() { return this.signupForm.get('email'); }

  get password() { return this.signupForm.get('password'); }

  get confirmPassword() { return this.signupForm.get('confirmPassword'); }

  onSubmit() {
     
    this.submitted = true;

    if (!this.signupForm.valid) {
      return false;
    } else {
      const email = this.signupForm.get('email').value;
      const password = this.signupForm.get('password').value;
      const nom = this.signupForm.get('nom').value;
      const prenom = this.signupForm.get('prenom').value;
      const classe = this.signupForm.get('classe').value;
        
      this.authService.signupUser(email,password,nom,prenom,classe);
    }
    
    
    
  }


  ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmedValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}


}
