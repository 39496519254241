

<div class="container text-center mh-100" *ngIf="formContent == undefined">
    <br> <br> <br> <br> <br>
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  
</div>



<div class="container" *ngIf="formContent !== undefined">
  
  <div class="row bg-transparent bg-gradient mb-4 border-bottom">
    <div class="col-sm-4"><h4 class="display-6 text-center">{{moduleName}}</h4></div>
    
    <div class="col-sm-4">
      <h4 class="display-6 text-center">{{quizTitle}}  </h4> 
    </div>
   
    <div class="col-sm-4">
      <h4 class="display-6 text-center">
        <!--<span class="badge .bg-transparent text-dark">{{_timer?.minutesDisplayed}} : {{_timer?.secondesDisplayed}}</span>-->
        {{_timer?.minutesDisplayed}} : {{_timer?.secondesDisplayed}}
      </h4>
    </div>

  </div>
  
  <ng-container *ngIf="mode=='quiz'">

      <div class="progress">
        <div class="progress-bar" role="progressbar" style="width: {{((activeStepIndex+1)/questionsNumber*100 | number: '1.0-0')+'%'}}" aria-valuenow="(activeStepIndex+1)/questionsNumber*100" aria-valuemin="0" aria-valuemax="100">{{((activeStepIndex+1)/questionsNumber*100 | number: '1.0-0')+'%'}}</div>
      </div>

    <hr>

    <!-- Modal -->
    <div class="row" >
      <a class="text-center link-info fs-5" href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Plan comptable</a>
    </div>
    
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Plan comptable</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <app-plan-comptable></app-plan-comptable>
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->    

    <hr>

      <form [formGroup]="form">

 

        <!-- questions -->
        <div formArrayName="questionArray">
          <div *ngFor="let question of questionArray['controls']; let questIndex=index" >
          
            <ng-container *ngIf="activeStepIndex===questIndex">

                <div class="row border rounded bg-dark alert alert-light" style="white-space: pre-wrap;" role="alert">
                  <span class="text-justify text-info align-middle "> {{stepItems[questIndex].texte}} </span>
                </div>

                
                <div formGroupName="{{questIndex}}">
                
                  
                  <div formArrayName="Transactions">
              
                    <div *ngFor="let transaction of getTransactions(questIndex).controls let transIndex=index">
                      <div formGroupName="{{transIndex}}" class="row gx-1 mb-3 p-2 border rounded border-info align-items-center">
                        <div class="col-sm-5 col-12">
                    
                          <label for="selectDebit" class="form-label">Debit</label>
                        
                          <ng-select formControlName="debit"class="form-control border-0"> 
                            <ng-option *ngFor="let compte of planComptable" [value]="compte.nom" >{{compte.numero}} - {{compte.nom}}</ng-option>
                          </ng-select>


      
                        
                        </div>
                        <!--<div class="col-sm col-3">
                          <label for="debitNat" class="form-label">Nat.</label>
                          <input class="form-control" id="debitNat" type="text" formControlName="debitNat" placeholder="Nature"> 
                        </div>-->
                        <div class="col-12 col-sm-5">
                          <label for="selectCredit" class="form-label">Credit</label>
                          <ng-select formControlName="credit"class="form-control border-0"> 
                            <ng-option *ngFor="let compte of planComptable" [value]="compte.nom" >{{compte.numero}} - {{compte.nom}}</ng-option>
                          </ng-select>
                        </div>
                        <!--<div class="col-sm col-3">
                          <label for="creditNat" class="form-label">Nat.</label>
                          <input class="form-control" id="creditNat" type="text" formControlName="creditNat" placeholder="Nature">
                        </div>-->
                        <div class="col-sm-2 col-12">
                          <label for="montant" class="form-label">Montant</label>
                          
                          <div class="input-group">
                            <input type="number" class="form-control" formControlName="montant" placeholder="Montant" aria-label="Input group example" aria-describedby="basic-addon1">
                            <span class="input-group-text" id="basic-addon1" (click)="removeTransaction(questIndex,transIndex)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16" >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                              </svg>
                            </span>
                          </div>
                        </div> 

                        </div>

                    </div>
                  </div>
                </div>

                <button type="button" class="btn btn-secondary" (click)="addTransaction(questIndex)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
                  </svg>
                </button>

              </ng-container>
          </div>
        
        </div>


      



          <div class="button-container row mt-3">

            



  
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" [ngClass]="{'disabled tabindex=-1' :activeStepIndex === 0}" style="cursor: pointer;"><a  class="page-link"  (click)="goToStep('prev');">Précédent</a></li>
              <li class="page-item" [ngClass]="{'disabled tabindex=-1':activeStepIndex === questionsNumber-1}" style="cursor: pointer;"><a  class="page-link"   (click)="goToStep('next');">Suivant</a></li>
            </ul>
          </nav>
          </div>


          <div class="button-container row mt-3">
          <button *ngIf="activeStepIndex === stepItems?.length - 1" type="button" class="btn btn-danger" (click)="onFormSubmit()">Valider</button>
          </div>
          
      </form>

  <!--
  <h5>Field Values</h5>
		<pre style="font-size:15px">{{ form.value | json }}</pre>
  -->
 
  </ng-container>

  <div class="result" *ngIf="mode=='result'">

    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
      <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
      </symbol>
      <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
      </symbol>
      <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
      </symbol>
    </svg>



    <div class="p-2 mb-2 bg-light border rounded-3">
      <div class="container-fluid py-2">
       
        <h3 class="display-5 fw-bold">Pourcentage de réussite: {{pourcentageReussite/questionsNumber | percent}}</h3>
        <h6 *ngIf="resultat.isBest==true && resultat.isFirst==false" class="text-success">👏{{resultat.points}} points!! BRAVO! Vous progressez! Vous avez battu votre record de points sur cet exercice!</h6>
        <h6 *ngIf="resultat.isBest!=true || resultat.isFirst==true"class="col-md-12 fs-4" class="text-info">{{resultat.points}} points</h6>
        <p class="col-md-12 fs-4">{{message1}}</p>
        <br>
        <div class="text-end">
          <p class="fst-italic">"{{message2}}"</p>
        </div>
       
      </div>
    </div>

    <div *ngFor="let question of reviewAnswers; let index = index">
      <div class="result-question border border-2 rounded" style="white-space: pre-wrap;"> 
        <p class="p-1 m-2 fw-bold">{{index + 1}}. {{question.texte}}</p>
        
        <div class="p-1 m-2 alert {{ question.isCorrect == true ? 'alert-success': 'alert-danger'}}">
            
          <table class="table">
            <thead>
              <tr>
                <th style="width: 40%" scope="col">Débit</th>
                <!--<th scope="col">Nat.</th>-->
                <th style="width: 40%" scope="col">Crédit</th>
                <!--<th scope="col">Nat.</th>-->
                <th style="width: 20%" scope="col">Montant</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let transaction of this.form.value['questionArray'][index]['Transactions'] let transIndex=index">
                <tr>
                  <td>{{transaction.debit}}</td>
                  <!--<td>{{transaction.debitNat}}</td>-->
                  <td>{{transaction.credit}}</td>
                  <!--<td>{{transaction.creditNat}}</td>-->
                  <td>{{transaction.montant | number}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <ng-container *ngIf="question.isCorrect==false">
            <svg class="bi flex-shrink-0 me-2" width="24" height="24"><use xlink:href="#exclamation-triangle-fill"/></svg>
          </ng-container>
          <ng-container *ngIf="question.isCorrect==true">
            <svg class='bi flex-shrink-0 me-2' width='24' height='24'><use xlink:href='#check-circle-fill'/></svg>
          </ng-container>
          <span>Votre réponse est {{ question.isCorrect == true ? 'juste': 'fausse'}}.</span>

      </div>
    </div>
    <hr>
    </div>
    
    <div class="d-grid gap-2">
      <button class="btn btn-primary" type="button" (click)="backToModule()">Retour aux modules</button>
    </div>

  </div>
 
</div>   

