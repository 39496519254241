import { Injectable, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HotToastService } from '@ngneat/hot-toast';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Module } from 'src/app/shared/models/module';
import { QuestionJournal } from 'src/app/shared/models/questionJournal';
import { QuizJournal, QuizJournalToStore } from 'src/app/shared/models/quizJournal';


@Injectable({
  providedIn: 'root'
})
export class QuizJournalService {

  constructor(private angularBdd: AngularFirestore, private toast: HotToastService) { }

  getJournalByID(idJournal: string){
    

    
    return this.angularBdd.collection('quiz-journal').doc(idJournal).valueChanges() as any;
    
    
   
  }

  createQuizJournal(quizJournal: QuizJournal, module:Module) {
    
    
    return new Promise<any>((resolve, reject) =>{
        this.angularBdd
            .collection("quiz-journal")
            .add(JSON.parse(JSON.stringify(quizJournal)))
            .then(res => {
                //add journal to module
                const m = this.angularBdd.doc("modules/"+module.id);
                
                m.update({
                  journaux: firebase.default.firestore.FieldValue.arrayUnion({journalId: res.id, journalTitre: quizJournal.titre})
                })

                this.toast.success("journal importé!");

            }, err => reject(err));
    });
  }

  
}
