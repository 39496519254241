<!-- Bannière -->
<div class="jumbotron text-center">  
      <h1 class="display-4 text-white">
            <strong> S'exercer à la comptabilité, partout!</strong>
      </h1>
      <h2 class="text-white">
      <strong> Atteignez plus d'objectifs en moins de temps.</strong>
      </h2>
      <p class="lead mt-4">
   <a class="btn btn-success btn-lg"  role="button" routerLink="/go">
        <strong>C'est parti !</strong>
       </a>  

      </p>
</div>
