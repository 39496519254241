import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { AuthentificationService } from '../../services/authentification.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(public authService: AuthentificationService,private router: Router) { }

  ngOnInit(): void {
    
  }

  logOut(): void{

    this.authService.SignOut();
    this.router.navigate(['/home']);
  }

}
