import { AngularFirestore } from "@angular/fire/firestore";


export class Resultat {
   
    quizId: string;
    userId: string; 
    points: number;
    pourcentageReussite: number;
    createdDate: any;
    module: string;
    quizTitle: string;
    isBest: boolean;
    isFirst: boolean;


    
    constructor(quizId: string, userId: string, points: number, pourcentageReussite: number, moduleId: string, quizTitle: string)
    {
        this.quizId = quizId;
        this.userId = userId;
        this.points = points;
        this.pourcentageReussite = pourcentageReussite;
        this.createdDate = new Date();
        this.module = moduleId;
        this.quizTitle = quizTitle;         
    }

    updatePoints(temps: number){
        
        if(temps < 1000){
            //this.points += Math.round((999 / temps));
            this.points += 1000-temps;
     
        }

        
    }

    isBestResultat() {
        
    }




}




