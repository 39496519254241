import { Component, OnInit } from '@angular/core';
import { CompteService } from 'src/app/core/services/compte.service';
import { Compte } from 'src/app/shared/models/compte';

@Component({
  selector: 'app-plan-comptable',
  templateUrl: './plan-comptable.component.html',
  styleUrls: ['./plan-comptable.component.css']
})
export class PlanComptableComponent implements OnInit {

  comptesActif: Compte[]=[];
  comptesPassif: Compte[]=[];
  comptesCharge: Compte[]=[];
  comptesProduit: Compte[]=[];


  constructor(private _compteService: CompteService, ) { }

  ngOnInit(): void {

    this.comptesActif = this._compteService.getComptesByNature("Actif");
    this.comptesPassif = this._compteService.getComptesByNature("Passif");
    this.comptesCharge = this._compteService.getComptesByNature("Charges");
    this.comptesProduit = this._compteService.getComptesByNature("Produits");
  }

}
