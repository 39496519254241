import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthentificationService } from 'src/app/core/services/authentification.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  forgotForm: FormGroup;
  errorMessage: string;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthentificationService,
    private router: Router,
    private alert: AlertService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get email() { return this.forgotForm.get('email'); }

  onSubmit() {
     
    this.submitted = true;

    if (!this.forgotForm.valid) {
      return false;
    }else{
      const email = this.forgotForm.get('email').value;

      this.authService.resetPasswordInit(email) 
        .then(() => {
            alert('Un lien pour redéfinir votre mot de passe vous a été envoyé à votre adresse email');
            this.router.navigate(['/login']);
        }
        ) 
        .catch(e => alert('An error occurred while attempting to reset your password')
      ); 
    }
   
    
        
    //this.authService.SignIn(email,password);
  }

  

}
