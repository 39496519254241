
<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
  <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
  </symbol>
  <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
  </symbol>
  <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
  </symbol>
</svg>

<div class="container px-4 py-2">

  <p class="btn btn-primary" (click)="refreshComponent()" >
    Retour au choix du niveau
  </p>

  <!--Pour l'utilisateur test, cacher le site web NOTION-->
  <ng-container *ngIf="authService.user$ | async as user">
    <ng-container *ngIf="user.class != 'MP1f'">
      <div class="alert alert-primary d-flex align-items-center" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>
        <div>
          
           <a href="{{urlTheorie}}" target="_blank" class="link-primary">Notions théoriques </a> 🤓
        </div>
      </div>
    </ng-container>
  </ng-container>





    <h2 class="pb-2 border-bottom">{{degreID}}</h2>
    <div class="row gy-3">
       
       
        <div class="col-sm-6" *ngFor="let module of modules; let i=index">
            
            
            <div class="card">
                <div class="card-header">
                    <h4> Module {{i+1}} -  {{module.titre}}</h4>
                </div>
                <div class="card-body">
                   
                    <p class="card-text border-bottom ">{{module.libelle}}</p>
                    
                    <!--<p *ngIf="module.url" class="card-text border-bottom"><a target="_blank" href={{module.url}} class="link-warning">Cliquez ici pour quelques éléments théoriques</a></p>-->
                </div>

        

            
              
              <div class="accordion accordion-flush" id="accordionFlushExample">
              
               <!-- <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne"> 
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-1'+i" aria-expanded="false" [attr.aria-controls]="'flush-1'+i">
                      Notions théoriques
                    </button>
                  </h2>
                  <div [attr.id]="'flush-1'+i" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <ul class="list-group" *ngFor="let notion of module.notions | keyvalue">
                       
                        <a href="{{notion.value}}" target="_blank" class="list-group-item list-group-item-action">
                          {{notion.key}}
                          
                        </a>
                        
                        
                      </ul>
                    </div>
                  </div>
                </div>-->
                <div class="accordion-item" *ngIf="module.journaux?.length >0">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-2'+i" aria-expanded="false" [attr.aria-controls]="'flush-2'+i">
                      Exercices - Journaux comptables
                    </button>
                  </h2>
                  <div [attr.id]="'flush-2'+i" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body" >
                      <ul class="list-group" *ngFor="let journal of module.journaux; let j=index">
                        <a class="list-group-item list-group-item-action" (click)="selectJournal(module, journal.journalId)">
                          {{journal["journalTitre"]}}
                        </a>
                     
                        </ul>
                    </div>
                  </div>
                </div>
              
                
            

              </div>
            </div>
        </div>
       




</div>





