import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ɵangular_packages_platform_browser_platform_browser_h } from '@angular/platform-browser';
import { RESOURCE_CACHE_PROVIDER } from '@angular/platform-browser-dynamic';
import * as firebase from 'firebase';
import { result } from 'lodash';
import { format } from 'path';
import { combineLatest, forkJoin, from, Observable, of, zip } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { catchError, concatMap, first, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { Module } from 'src/app/shared/models/module';
import { QuizJournal } from 'src/app/shared/models/quizJournal';
import { Resultat } from 'src/app/shared/models/resultat';
import { Reward } from 'src/app/shared/models/reward';

import { User } from 'src/app/shared/models/user';
import { RewardService } from './reward.service';


@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(private angularBdd: AngularFirestore, private _rewardService: RewardService) { }

  
  addJournalToModule(qj : QuizJournal){

  }
  
  getAllModules(){
    return this.angularBdd.collection<Module>('modules', ref => ref.orderBy('order', 'asc')).valueChanges({idField: 'id'});

  }

  getModulesByDegre(degre: string){

    // Create a query against the collection.
    return this.angularBdd.collection('modules', ref => ref.where("degres","array-contains",degre).orderBy('order')).snapshotChanges();

  }

  getJournauxbyModule(moduleId: string){
    return this.angularBdd.doc<Module>('modules/'+moduleId).valueChanges().pipe(first(),map(module=> {return module.journaux}));
  }

  setModuleScore(moduleId: string, userId: string){

     this.getJournauxbyModule(moduleId).pipe(switchMap(array =>{

        const obs$ = array.map(item=>{

          return this.angularBdd.collection('resultats', ref => ref.where('quizId','==', item.journalId).where('userId','==', userId).where('isBest','==', true)).valueChanges().pipe(first())
          
         
        })

        return forkJoin(obs$);
        

     })).subscribe((finalArray)=>{
    
       let test : boolean = true;
       let compteurEx: number = 0;
       let pourcentageReussite: number = 0;
       //console.log(finalArray);
       finalArray.forEach((journal:any)=>{
        
        //console.log(journal);
        if(journal.length==0){
          test = false;
        }else{
          compteurEx +=1;
          pourcentageReussite += journal[0].pourcentageReussite;
        }
        

       })

       if(test == true){
        //console.log(pourcentageReussite / compteurEx);
        const rew: Reward = new Reward();
        rew.moduleId = moduleId;
        rew.pourcentage = pourcentageReussite / compteurEx;
        if(rew.pourcentage<0.6){
          rew.rating = 1;
        }else if(rew.pourcentage <0.8){
          rew.rating = 2;
        }else if(rew.pourcentage <1){
          rew.rating = 3;
        }else{
          rew.rating = 4;
        }
        //console.log(rew);
        this._rewardService.createReward(userId, rew);
        
      }else{
        console.log("module pas terminer");
        
      }

       

     })

    

      
  }
}





        
   
  

      /*
      this.angularBdd.doc<Module>('modules/'+moduleId).valueChanges().pipe(first(),
        map(module => {
            //console.log(module);
            const j = module.journaux.map((h:any) => {
              
              //console.log(h.journalId);
              this.angularBdd.collection('resultats', ref => ref.where('quizId','==', h.journalId).where('userId','==', userId).where('isBest','==', true)).valueChanges().pipe(first(),map((resultat:any) =>{
                if(resultat[0]){
                  console.log("OUI");
                  compteurEx +=1;
                  pourcentageReussite += resultat[0].pourcentageReussite;
                }else{
                  console.log("NON");
                  test=false;
                }
              })).subscribe(l=>{
                  console.log(test);

                  
              })

              return h.journalId
            
            })
            
            return j;
        })  
      )
      .subscribe(res => {
            //console.log(test);
          
           // this.angularBdd.collection('resultats', ref => ref.where('quizId','==', r).where('userId','==', userId).where('isBest','==', true)).valueChanges())
            
          

      })

  }








    
                //le module est terminé, on crée un reward
                if(test == true){
                  //console.log(pourcentageReussite / compteurEx);
                  const rew: Reward = new Reward();
                  rew.moduleId = moduleId;
                  rew.pourcentage = pourcentageReussite / compteurEx;
                  if(rew.pourcentage<0.8){
                    rew.rating = 1;
                  }else if(rew.pourcentage <0.9){
                    rew.rating = 2;
                  }else if(rew.pourcentage <1){
                    rew.rating = 3;
                  }else{
                    rew.rating = 4;
                  }
                    
                  this._rewardService.createReward(userId, rew);
                  
                }else{
                  console.log("module pas terminer");
                }*/