<div class="row justify-content-md-center">
    <div class="col-sm-6">
  
      <div class="card mx-auto">
        <h4 class="card-header">Inscription</h4>
        <div class="card-body">
  
        <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
      
        <div class="form-floating form-group mb-2">
                <input type="nom"
                        id="floatingNom"
                        class="form-control"
                        placeholder="Nom"
                        formControlName="nom"
                         [ngClass]="{ 'is-invalid': submitted && nom.errors}" />
                        <div *ngIf="submitted && nom.errors" class="invalid-feedback">
                            <div *ngIf="nom.errors.required">Nom requis</div>
                            
                          </div>
      
                          
                        <label for="floatingNom">Nom</label>
        </div>

        <div class="form-floating form-group mb-2">
            <input type="prenom"
                    id="floatingPrenom"
                    class="form-control"
                    placeholder="Prénom"
                    formControlName="prenom"
                     [ngClass]="{ 'is-invalid': submitted && prenom.errors}" />
                    <div *ngIf="submitted && prenom.errors" class="invalid-feedback">
                        <div *ngIf="prenom.errors.required">Prénom requis</div> 
                      </div>
  
                      
                    <label for="floatingPrenom">Prénom</label>
          </div>

            <div class="form-floating form-group mb-2">
              <select class="form-select form-control" formControlName="classe" id="floatingSelect" aria-label="Floating label select example" [ngClass]="{ 'is-invalid': submitted && classe.errors}">
                <option value="" disabled>Choix de la classe</option>
                <option *ngFor="let classe of classes" [ngValue]="classe">{{classe}}</option>
              </select>
              <div *ngIf="submitted && classe.errors" class="invalid-feedback">
                <div *ngIf="classe.errors.required">Classe requise</div>
              </div>
              <label for="floatingSelect">Classe</label>
            </div>


          <div class="form-floating form-group mb-2">
            <input type="email"
                    id="floatingMail"
                    class="form-control"
                    formControlName="email"
                    placeholder="name@example.com" [ngClass]="{ 'is-invalid': submitted && email.errors}" />
                    <div *ngIf="submitted && email.errors" class="invalid-feedback">
                        <div *ngIf="email.errors.required">Adresse email requise</div>
                        <div *ngIf="email.errors.email">Format de l'adresee incorrect</div>
                      </div>
  
                      
                    <label for="floatingMail">Adresse email</label>
          </div>
          <div class="form-floating form-group mb-2">
            <input type="password"
                    id="floatingPassword"
                    class="form-control"
                    placeholder="Password"
                    formControlName="password" [ngClass]="{ 'is-invalid': submitted && password.errors}" >
  
                    <div *ngIf="submitted && password.errors" class="invalid-feedback">
                      <div *ngIf="password.errors.required">Mot de passe requis</div>
                      
                  </div>
                    
            <label for="floatingPassword">Password</label>
          </div>

          <div class="form-floating form-group mb-2">
            <input type="password"
                    id="floatingConfirmPassword"
                    class="form-control"
                    placeholder="Confirmer Password"
                    formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && confirmPassword.errors}" >
  
                    <div *ngIf="submitted && confirmPassword.errors" class="invalid-feedback">
                      <div *ngIf="confirmPassword.errors.required">Confirmer le mot de passe</div>
                      <div *ngIf="confirmPassword.errors.confirmedValidator">Les deux mots de passe ne concordent pas.</div>
      
                  </div>
                    
            <label for="floatingConfirmPassword">Confirmer password</label>
          </div>
  
          
          <hr>
          <button class="w-100 btn btn-lg btn-primary" type="submit">S'inscrire</button>
  
          <hr>
          
          
        </form>
  
        </div>
      </div>
    </div>
  </div>

  