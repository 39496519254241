import { Reward } from "./reward";


export class User { 
   uid: string;
   email: string;
   displayName: string;
   photoURL?: string;
   emailVerified: boolean;
   nom?: string;
   prenom?: string;
   class?: string;
   score?: number;
   rewards?: Reward[];
   admin?:boolean;
}
