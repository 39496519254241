import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { User } from 'src/app/shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private angularBdd: AngularFirestore) { }

  getClassement(classe: string): Observable<User[]>{

    return this.angularBdd.collectionGroup<User>('users', ref => ref.where('class', '==', classe).orderBy("score", "desc").orderBy("nom","asc").limit(5)).valueChanges().pipe();

  }

  updatePhotoURL(id: string, url: string){

    this.angularBdd.doc(`users/${id}`).update({photoURL: url})
    
  }
}
