import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { ModuleService } from 'src/app/core/services/module.service';
import { Module } from 'src/app/shared/models/module';
import { QuizJournal } from 'src/app/shared/models/quizJournal';


@Component({
  selector: 'app-degre-detail',
  templateUrl: './degre-detail.component.html',
  styleUrls: ['./degre-detail.component.css']
})
export class DegreDetailComponent implements OnInit {

  user$=this.authService.user$;
  
  modules : Module[];
  urlTheorie: String;

  @Input() degreID = '';

  @Output() moduleSelectedEvent = new EventEmitter<{module: Module, journalId: string}>();



  constructor(private modulesService: ModuleService, public authService: AuthentificationService, private activatedroute:ActivatedRoute, private router: Router) { }

  ngOnInit(): void {


    if(this.degreID==null){this.degreID="MP1"}

    //affichage du lien Notion sur la théorie
    if (this.degreID=="MP1"){
      this.urlTheorie = "https://divcom.notion.site/MP1-2f8018630c7643bc9bd106e370dcee30";
    }else if(this.degreID=="MP2"){
      this.urlTheorie = "https://divcom.notion.site/MP2-f21aafac4d0f418ba9eb028357051eb1";
    }else{
      this.urlTheorie = "https://divcom.notion.site/MP3-99f8d5f16cca467780baa90319605e00";
    }

    this.modulesService.getModulesByDegre(this.degreID).pipe().subscribe(data => {
      
      this.modules = data.map((e:any) => {
        
        return {
          
          id: e.payload.doc.id,...e.payload.doc.data() as Module
        } ;

       // return e as Module;
      })

    
    
    });

    

    
    
  }


  
  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  refreshComponent(){
    this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
      this.router.navigate(['go']);
  }); 


  }

  selectJournal(module: Module, journalId : string){
    

    this.moduleSelectedEvent.emit({module, journalId});
    

    //this.visible = false;

  }





}
