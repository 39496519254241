import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { RouterModule } from '@angular/router';
import { $ } from 'protractor';
import * as firebase from 'firebase';
import { ResultatService } from 'src/app/core/services/resultat.service';
import { Resultat } from 'src/app/shared/models/resultat';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { ImageUploadService } from 'src/app/core/services/image-upload.service';
import { UserProgressComponent } from './progress/user-progress/user-progress.component';
import { UserService } from 'src/app/core/services/user.service';
import { HotToastService } from '@ngneat/hot-toast';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  user$=this.authService.user$;
  uploadProgress$: Observable<number>
  
  resultats: Resultat[]=[];
  
  
  constructor(public userService: UserService, public authService: AuthentificationService, public resultatsService: ResultatService, private storage : AngularFireStorage, private toast: HotToastService ) { }

  ngOnInit(): void {
    
  }

  uploadImage(event: any, user: any){

     

    const storageRef = this.storage.ref("images/profile/"+user.uid);
    const uploadTask = this.storage.upload("images/profile/"+user.uid, event.target.files[0]);
    this.uploadProgress$ = uploadTask.percentageChanges();
    
    uploadTask.snapshotChanges().pipe(
        switchMap((resultat) => {
          //console.log(storageRef.getDownloadURL());
          return storageRef.getDownloadURL()
        }),
        this.toast.observe(
          {
            
            loading: "Chargement...",
            success: (s) => "Image uploadée",
            error: (e) => 'Une erreur s\'est produite...',
          }),
          map((photoURL:any) => {
       
            this.userService.updatePhotoURL(user.uid, photoURL);
            
        }
        )).subscribe(r=>{
          
        })
    
  }

  
}
