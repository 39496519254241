<div class="accordion accordion-flush" id="accordionFlushExample">

  <div class="accordion-item" *ngFor="let degre of degres; let degreIndex=index">
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingOne"> 

   
        
          <button [ngClass]="((authService.user$ | async)?.class+''|slice:0:3)  === degre.nom ? 'accordion-button' : 'accordion-button collapsed'" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-1'+degreIndex" aria-expanded="false" [attr.aria-controls]="'flush-1'">
          
          
            {{degre.nom }} -  Réussite: 
           
         </button>
      
         

      </h2>
    </div>

   <!-- <ng-container *ngIf="userClass === degre.nom; else loggedOut">-->
      <div [attr.id]="'flush-1'+degreIndex" [ngClass]="((authService.user$ | async)?.class |slice:0:3) === degre.nom ? 'accordion-collapse show' : 'accordion-collapse collapse'" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">

    
      <div class="accordion-body ps-2">

        <div class="container ps-0 mb-3" *ngFor="let module of degre.modules; let moduleIndex=index">
          <div class="row">
            <h6>{{module.titre}}</h6>
            <hr>
          </div>

          <div class="row" *ngFor="let journal of module.journaux; let journalIndex=index">
            <div class="col-7">
              {{journal.journalTitre}}
            </div>
     
            <div class="col-5">
              <app-user-progress quizId={{journal.journalId}}></app-user-progress>
            </div>
          
          </div>
        </div>
      </div>
     </div>


  </div>
  


  </div>
