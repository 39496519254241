import { Component, OnInit } from '@angular/core';
import { combineLatest, forkJoin } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { DegreService } from 'src/app/core/services/degre.service';
import { ModuleService } from 'src/app/core/services/module.service';
import { ResultatService } from 'src/app/core/services/resultat.service';
import { Degre } from 'src/app/shared/models/degre';
import { Module } from 'src/app/shared/models/module';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit {

  
  modules: Module[]=[];
  degres: Degre[]=[];
  quizId: string;
  


  constructor(private modulesService: ModuleService, private degreService: DegreService, public resultatsService: ResultatService, public authService: AuthentificationService) { }

  ngOnInit(): void {



    this.degres = this.degreService.getAll();

    this.degres.forEach(degre => {
      this.modulesService.getModulesByDegre(degre.nom).subscribe(res => {
        this.modules = res.map (e  => {
          //return e as Module
          return { id: e.payload.doc.id,...e.payload.doc.data() as Module}
        })

        degre.modules = (this.modules);

        //look for result
    
      })
    })

    
  }



  getUser () { 
   
    const user = JSON.parse(localStorage.getItem('user'));
    return user;

    
  }

}
