import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicModule } from '../public/public.module';
import { ProtectedModule } from '../protected/protected.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './components/alert/alert.component';
import { Alert } from '../shared/models/alert';
import { AdminModule } from '../admin/admin.module';



@NgModule({
  declarations: [NavbarComponent, AlertComponent],
  imports: [
    CommonModule,
    PublicModule,
    ProtectedModule,
    RouterModule,
    AdminModule
  ],
  exports: [ NavbarComponent, AlertComponent ]
})
export class CoreModule { 
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) { 
     if (parentModule) { 
      throw new Error('CoreModule is already loaded.'); 
     } 
    } 
 } 
