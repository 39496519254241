import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-features',
  templateUrl: './home-features.component.html',
  styles: [
  ]
})
export class HomeFeaturesComponent implements OnInit {

  features: Array<any>;

  constructor() { }

  ngOnInit(): void {

    this.features = [
          {
           title: 'S\'exercer',
           description: 'Pratiquer partout et tout le temps',
           icon: 'assets/img/calendar.png'
          },
          {
           title: 'Progresser',
           description: 'Atteindre ses objectifs',
           icon: 'assets/img/award.png'
          },
          {
           title: 'Analyser',
           description: 'Visualiser le travail accompli',
           icon: 'assets/img/diagram.png'
          }
         ];
      
  }

}
