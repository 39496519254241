import { NgModule } from '@angular/core';


import { ProtectedRoutingModule } from './protected-routing.module';
import { SharedModule } from '../shared/shared.module';

import { DegreDetailComponent } from './degre-detail/degre-detail.component';
import { DegreComponent } from './degre/degre.component';
import { ProfileComponent } from './profile/profile.component';
import { ProtectedComponent } from './protected.component';
import { TimelineComponent } from './profile/timeline/timeline.component';
import { QuizJournalComponent } from './quiz-journal/quiz-journal.component';
import { QuizJournal } from '../shared/models/quizJournal';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProgressComponent } from './profile/progress/progress.component';
import { UserProgressComponent } from './profile/progress/user-progress/user-progress.component';
import { ScoreComponent } from './profile/score/score.component';
import { RewardComponent } from './profile/reward/reward.component';
import { PlanComptableComponent } from './quiz-journal/plan-comptable/plan-comptable.component';




@NgModule({
  declarations: [DegreDetailComponent, DegreComponent, ProfileComponent, ProtectedComponent, TimelineComponent, QuizJournalComponent, ProgressComponent, UserProgressComponent, ScoreComponent, RewardComponent, PlanComptableComponent],
  imports: [
    SharedModule,
    ProtectedRoutingModule,
    NgSelectModule
  ]
})
export class ProtectedModule { }
