import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { ResultatService } from 'src/app/core/services/resultat.service';
import { Resultat } from 'src/app/shared/models/resultat';

@Component({
  selector: 'app-user-progress',
  templateUrl: './user-progress.component.html',
  styleUrls: ['./user-progress.component.css']
})
export class UserProgressComponent implements OnInit {

  @Input() quizId: string;
  pourcentage : number;
  resultats : any[];
  userId : string;

  constructor(private ResultatService: ResultatService, private authService : AuthentificationService) { }

  ngOnInit(): void {
    
    this.authService.user$.pipe(first()).subscribe( user => {
      if (user) { 
        this.userId = user.uid;
        this.getBestPOurcentage();
      }
    });

  }

  getBestPOurcentage(){
    this.ResultatService.getBestPourcentage(this.userId, this.quizId).pipe(first()).subscribe((res: any) =>{

      this.resultats = (res.length!=0) ? res : undefined;

    }
    )
  }

}
