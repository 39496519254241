import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImportJournalComponent } from './import-journal/import-journal.component';

const routes: Routes = [
  { path: 'importJournal', component: ImportJournalComponent }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
