
<div *ngIf="resultats; else elseBlock" class="progress position-relative">

        
        <div class="progress-bar" role="progressbar" [ngStyle]="{'width.%':resultats[0].pourcentageReussite*100}" aria-valuenow="resultats[0].pourcentageReussite" aria-valuemin="0" aria-valuemax="100"></div>
        <small class="justify-content-center d-flex position-absolute w-100">{{resultats[0].pourcentageReussite*100 | number:'1.1-1'}}%</small>
</div>

<ng-template #elseBlock>

    <div class="progress position-relative">
    
        <div class="progress-bar" role="progressbar" [ngStyle]="{'width.%':0}" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
        <small class="justify-content-center d-flex position-absolute w-100">0%</small>
    </div>  
</ng-template>

