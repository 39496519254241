import { AuthentificationService } from "src/app/core/services/authentification.service";

export class Reward { 
    moduleId: string;
    pourcentage: number;
    rating: number;
    url?: string;
    createdDate: any;

    constructor(){
        
    }
 }
 