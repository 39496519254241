import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/shared/models/user';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.css']
})
export class ScoreComponent implements OnInit {

  scores$ : Observable<User[]>;
  userClass: string;
  
  constructor(private userService: UserService, public authService : AuthentificationService) { }

  ngOnInit(): void {

      
    this.authService.user$.pipe(first()).subscribe( user => {
      if (user) { 
          
          this.userClass = user.class; 
          this.scores$ = this.userService.getClassement(this.userClass);
       }
    });
    
    

  }

}
