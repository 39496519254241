<div class="row justify-content-md-center">
    <div class="col-sm-6">
  
      <div class="card mx-auto">
        <h4 class="card-header">Importer une nouveau journal</h4>
        <div class="card-body">
  
        <form [formGroup]="importQuizForm" (ngSubmit)="onSubmit()">
      
  
          <div class="form-floating form-group mb-2">
            <input type="text"
                    id="floatingInput"
                    class="form-control"
                    formControlName="name"
                    placeholder="Nom du journal" [ngClass]="{ 'is-invalid': submitted && name.errors}" />
                   
                    <div *ngIf="submitted && name.errors" class="invalid-feedback">
                      <div *ngIf="name.errors.required">Nom requis</div>
                    </div>

            <label for="floatingInput">Nom du journal</label>
          </div>

          <div class="form-floating form-group mb-2">
            <select class="form-select form-control" formControlName="module" id="floatingSelect" aria-label="Floating label select example"  [ngClass]="{ 'is-invalid': submitted && module.errors}">
              <option value="" disabled>Choix du module</option>
  
              <option *ngFor="let module of modules$ | async; let moduleIndex=index" [ngValue]="module">{{module.titre}}</option>
            </select>

            <div *ngIf="submitted && module.errors" class="invalid-feedback">
              <div *ngIf="module.errors.required">Module requis</div>
            </div>

          
            <label for="floatingSelect">Module</label>
          </div>

          <div class="form-floating form-group mb-2">
            <!--<input type="file" (change)="onFileChange($event)" multiple="false" />-->
            <input (change)="onFileChange($event)" id="floatingFile" type="file"  multiple="false" formControlName="file" class="form-control" [ngClass]="{ 'is-invalid': submitted && file.errors}" />
          </div>

          <div *ngIf="submitted && file.errors" class="invalid-feedback">
            <div *ngIf="file.errors.required">File requis</div>
          </div>
         

          <hr>
            <button class="w-100 btn btn-lg btn-primary" type="submit">Importer!</button>

            <hr>
         
        </form>
  
        </div>
      </div>
    </div>

   
  </div>